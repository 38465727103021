import getConfig from "next/config";

declare global {
    interface Window {
        gtag: (...args: any[]) => void
        dataLayer: Record<string, any>
        OskarTS: any;
    }
}

/**
 * Are we currently within a browser.
 */
export const isBrowser = () => typeof window !== "undefined" && typeof window.document !== "undefined";

/**
 * Is the browser currently set to 768px or less wide.
 * Expects window.innerWidth;
 */
export const isMobile = () => isBrowser() && window.innerWidth <= 768;

// https://stackoverflow.com/a/8649003
/**
 * Parses a query param string and returns an object of the values
 * @param query - valid string to parse
 */
export const parseQueryParamsToObject = (query: string = "") => {
    let queryString = query;
    if (queryString && queryString[0] === "?") {
        queryString = queryString.substring(1);
    }
    try {
        return JSON.parse(
            `{"${decodeURI(queryString).replace(/"/g, "\\\"").replace(/&/g, "\",\"").replace(/=/g, "\":\"")}"}`
        );
    } catch {
        return null;
    }
};

/**
 * Returns the user's lat,lng from session storage in an array.
 */
export const getUserLatLng = () => {
    if (!isBrowser()) {
        return [null, null];
    }
    const lat = parseFloat(window.sessionStorage.getItem("carmen_lat"));
    const lng = parseFloat(window.sessionStorage.getItem("carmen_lon"));
    return [lat, lng];
};

/**
 * Take a name and determine if the feature should show or be hidden
 * based on environment variable.
 */
export const shouldShowFeature = (name: string) => {
    const { publicRuntimeConfig } = getConfig();

    const FEATURE_FLAGS = {
        example: "featureFlagExample"
    };

    if (FEATURE_FLAGS[name] && typeof publicRuntimeConfig[FEATURE_FLAGS[name]] !== "undefined") {
        return !(publicRuntimeConfig[FEATURE_FLAGS[name]] === "false");
    }
    return true;
};

export const toggleWindowFreeze = () => {
    const {
        body: {
            style
        }
    } = document;

    if (style.overflow === "hidden") {
        style.height = "auto";
        style.overflow = "auto";
    } else {
        style.height = "100vh";
        style.overflow = "hidden";
    }
};

/**
 * Extracts Optimize experiment id and variant id from data layer
 *
 */
export const getOptimizeData = () => {
    const allExperiments = [];
    if (isBrowser() && "dataLayer" in window) {
        const experiments = window.dataLayer.filter((x) => typeof x === "object" && "experimentID" in x);

        if (experiments.length > 0) {
            experiments.forEach((x: any) => {
                allExperiments.push({
                    experiment_id: x.experimentID,
                    variant_id: x.variantID
                });
            });
        }
    }
    return allExperiments;
};
